<script setup lang="ts">
import { MediaImageFragment, ParagraphEventTeasers } from '~/api/graphql/generated'
import { useEventTeasers } from '~/api/drupal'

const props = defineProps<{
  entity: ParagraphEventTeasers
}>()

const image = computed(() => {
  return props.entity?.fieldMobileImage?.entity || props.entity?.fieldImage?.entity
    ? {
        small: props.entity?.fieldMobileImage?.entity as MediaImageFragment,
        large: props.entity?.fieldImage?.entity as MediaImageFragment,
      }
    : undefined
})

const eventType = props.entity.fieldEventTypeTaxonomy?.entity?.tid?.toString()
const eventCategory = props.entity.fieldEventCategory?.entity?.tid?.toString()

const count = computed(() => props.entity?.fieldNumberOfEntries || 3)
const eventTypes = computed(() => (eventType ? [eventType] : []))
const eventCategories = computed(() => (eventCategory ? [eventCategory] : []))

const { items } = await useEventTeasers(
  eventTypes.value,
  eventCategories.value,
  count.value,
  `event-teasers--${props.entity.id}`,
)

const cta = computed(() => {
  return {
    url: props.entity.fieldLink?.url?.path || '',
    text: props.entity.fieldLink?.title || '',
  }
})
</script>

<template>
  <SectionSpecial
    :title="entity?.fieldTitle || ''"
    :sub-title="entity?.fieldDescription?.processed || ''"
    :image="image"
  >
    <h3 v-animate="{ animation: 'vertical-reveal' }">{{ entity.fieldEventItemsTitle }}</h3>
    <LinkListLarge>
      <template #default="{ isHovered }">
        <LinkListLargeItemEvent
          v-for="item in items"
          :key="item.id"
          v-animate="{ animation: 'vertical-reveal' }"
          :date="item.date"
          :title="item.title"
          :location="item.location"
          :link="item.link"
          :dim="isHovered"
        />
      </template>
    </LinkListLarge>
    <Button v-if="cta.url" secondary icon="offers" class="button-more" :to="cta.url"> {{ cta.text }} </Button>
  </SectionSpecial>
</template>
<style scoped lang="scss">
.button-more {
  margin-top: 48px;
}
</style>
